import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

const DefaultLayout = ({ children }) => {
  return (
    <>
      <Helmet>
        <script defer src="https://use.fontawesome.com/releases/v5.3.1/js/all.js" />
      </Helmet>

      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item content is-large is-header-link mb-0" to="/">
            Rentcontrol IO
          </Link>
        </div>
      </nav>

      {children}

      <footer className="footer">
        <p className="has-text-centered">
          &#169; 2021{' '}
          <a href="https://padfever.com" title="Padfever">
            Padfever
          </a>
        </p>
      </footer>
    </>
  );
};

export default DefaultLayout;
