import React from 'react';

import { Regulation } from '../../interfaces';
import RegulationBox from './RegulationBox';

interface Props {
  regulation: Pick<Regulation, 'allowed' | 'disallowed' | 'obligations'>;
}

const RegulationBoxes = (props: Props) => {
  const { regulation } = props;

  return (
    <div className="columns">
      <div className="column">
        <RegulationBox kind="ALLOWED" rules={regulation.allowed} />
      </div>
      <div className="column">
        <RegulationBox kind="DISALLOWED" rules={regulation.disallowed} />
      </div>
      <div className="column">
        <RegulationBox kind="OBLIGATIONS" rules={regulation.obligations} />
      </div>
    </div>
  );
};

export default RegulationBoxes;
