import React from 'react';

import { RegulationKind, RegulationRule } from '../../interfaces';

interface Props {
  kind: RegulationKind;
  rules: RegulationRule[];
}

interface TagsProps {
  kind: RegulationKind;
  rule: RegulationRule;
}

function pickColor(kind: RegulationKind) {
  switch (kind) {
    case 'ALLOWED':
      return 'is-fern-green';

    case 'DISALLOWED':
      return 'is-sweet-brown';

    case 'OBLIGATIONS':
      return 'is-queen-blue';

    default:
      return '';
  }
}

const TagsDesktop = (props: TagsProps) => {
  const { kind, rule } = props;
  const color = pickColor(kind);

  return (
    <div className="tags are-medium is-inline-flex-desktop is-hidden-mobile my-0">
      {rule.tags.map((tag) => (
        <span
          className={`tag ${color} is-capitalized mb-0`}
          key={`tag-desktop-${Buffer.from(tag, 'utf8').toString('hex')}`}
        >
          {tag}
        </span>
      ))}
    </div>
  );
};

const TagsMobile = (props: TagsProps) => {
  const { kind, rule } = props;
  const color = pickColor(kind);

  return (
    <div className="tags are-medium is-hidden-desktop mb-0 mt-1">
      {rule.tags.map((tag) => (
        <span
          className={`tag ${color} is-capitalized mb-0`}
          key={`tag-mobile-${Buffer.from(tag, 'utf8').toString('hex')}`}
        >
          {tag}
        </span>
      ))}
    </div>
  );
};

const Title = (props: Pick<Props, 'kind'>) => {
  const { kind } = props;

  return <h3 className="title is-capitalized">{kind.toLowerCase()}</h3>;
};

const RegulationBox = (props: Props) => {
  const { kind, rules } = props;
  const color = pickColor(kind);

  return (
    <div className={`regulation ${color}`}>
      <Title kind={kind} />
      <ul className="list">
        {rules.map((rule) => (
          <li key={`rule-${Buffer.from(rule.label, 'utf8').toString('hex')}`}>
            {rule.tags.length > 0 && (
              <TagsDesktop
                kind={kind}
                rule={rule}
                key={`tags-desktop-${Buffer.from(rule.label, 'utf8').toString('hex')}`}
              />
            )}
            <span className="is-inline ml-1">{rule.label}</span>
            {rule.tags.length > 0 && (
              <TagsMobile
                kind={kind}
                rule={rule}
                key={`tags-mobile-${Buffer.from(rule.label, 'utf8').toString('hex')}`}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RegulationBox;
