import cn from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import { useRegulations } from '../../hooks';

const StateRegulationLinks = () => {
  const regulations = useRegulations();

  return (
    <div className="columns is-multiline has-text-centered">
      {regulations.map(({ state_code, state_name, status }) => (
        <div className="column is-one-half is-one-third-desktop" key={state_code}>
          <Link
            to={`/laws/${state_code.toLowerCase()}`}
            title={`${state_name.toLowerCase()} laws`}
            className={cn({ 'is-capitalized': true, 'has-text-danger': status !== 'AVAILABLE' })}
          >
            {state_name.toLowerCase()}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default StateRegulationLinks;
