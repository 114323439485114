import { graphql, useStaticQuery } from 'gatsby';

import { Regulation } from '../interfaces';

export default function useRegulations(): Regulation[] {
  const {
    regulations: { edges },
  } = useStaticQuery(graphql`
    query {
      regulations: allRentControlRegulation {
        edges {
          node {
            status
            state_code
            state_name
          }
        }
      }
    }
  `);

  return edges.map(({ node }) => node);
}
